import React, { Component } from "react";
import { connect } from "react-redux";
import { setToInitial } from "../../../actions/loginActions";
import CircularProgress from "@mui/material/CircularProgress";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, stackTrace: null };
  }

  //   static getDerivedStateFromError(error) {
  //     // Update state to show the fallback UI during the next render phase
  //     return { error };
  //   }

  componentDidCatch(error, info) {
    // logging the error details
    this.setState({ error, stackTrace: info });
    this.timer = setTimeout(() => {
      const { setToInitial } = this.props;
      setToInitial();
      window.location = `/login?next=${window.location.pathname}`;
    }, [5000]);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (this.state.error) {
      // Return the fallback UI
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            background: "#F9FAFA",
          }}
        >
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
              columnGap: 10,
              position: "relative",
            }}
          >
            <CircularProgress size={"200px"} />
            <h3 style={{ marginTop: 10, position: "absolute" }}>Updating...</h3>
          </div>
          <div style={{ padding: 10, maxWidth: "50%" }}>
            <p style={{ textAlign: "center" }}>
              Cause: {this.state.error.toString()}
            </p>
            <p>Stack trace: {this.state.stackTrace.componentStack}</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default connect(null, { setToInitial })(ErrorBoundary);
